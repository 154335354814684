@import url('https://fonts.googleapis.com/css2?family=Righteous&family=Roboto+Condensed&family=Bebas+Neue&display=swap');
@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.scrollbar-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.text-field {
  width: calc(100% - 30px);
}
